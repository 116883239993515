import { OutboundLink } from 'gatsby-plugin-google-analytics'
import * as React from 'react'
import { IoLogoInstagram, IoLogoTwitter } from 'react-icons/io'

import SocialProfile from '../../components/social-profile/social-profile'
import Photo from '../../images/about.jpg'
import DustPuppy from '../../images/dustpuppy.png'
import { AboutDetails, AboutImage, AboutWrapper, SocialProfiles } from './style'

const SocialLinks = [
  {
    icon: <IoLogoInstagram />,
    url: "https://www.instagram.com/rduraj.dev/",
    tooltip: "Instagram",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/RobertDuraj",
    tooltip: "Twitter",
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <AboutWrapper>
      <AboutImage>
        <img className="photo" src={Photo} alt="logo" />
        <img className="dustpuppy" src={DustPuppy} alt="dustpuppy" />
      </AboutImage>

      <AboutDetails>
        <h2>Hello!</h2>

        <p>
          <strong>
            My name is Robert. I'm Husband, Father, Programmer and Jedi Knight.
          </strong>
        </p>

        <p>
          I work as a software engineer. After hours I’m saving the
          universe in the name of the Jedi Order. It’s also OK for me to switch
          my X-Wing to good coffee and book.
        </p>

        <p>
          In the past, I spoke at conferences and conducted inner workshops.
          Right now, I want to extend my activity to blogging.
        </p>

        <p>
          My mission is to go deeper and deeper into programming and IT every day. And share all my insights with the community.
          I want to treat this place as a notebook and playground for my experiments. I hope you
          will find it interesting. If you want to let me know about this - don't hesitate and contact me any time!
          Best way to do this is <OutboundLink href="https://twitter.com/RobertDuraj"><IoLogoTwitter /></OutboundLink>.
        </p>

        <p>
          Please take a seat, grab a coffee, and feel comfortable on my website.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
