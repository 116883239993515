import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import About from '../containers/about'

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="About"
        description="My name is Robert. I’m a husband, father, and programmer. And this is my DailyCoding blog."
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
